var geocoder;
function geocodedata(){
    return new Promise(function(resolve, reject) {
        getdata().then(function(results) {
            // when geocoder is done log the results in console
           // console.log(33);
           resolve(results);
        })
        // .catch only runs when promise is rejected
        .catch(function(status) {
        
        });
    })
}
function getdata(){
    geocoder = new google.maps.Geocoder();
    if (navigator.geolocation) {
        return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(function successFunction(position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            
             codeLatLng(lat, lng).then(function(results) {
                // when geocoder is done log the results in console
                resolve(results);
              })
              // .catch only runs when promise is rejected
              .catch(function(status) {
                reject(new Error('Couldnt\'t find the location ' + status));
               
              });
            ///console.log("ppppppppppppppppppppppp")
        }, errorFunction);
    });
    } 
}
  
//Get the latitude and the longitude;


function errorFunction(){
    alert("Geocoder failed");
}

  function initialize() {
    geocoder = new google.maps.Geocoder();
  }

  function codeLatLng(lat, lng) {

    var latlng = new google.maps.LatLng(lat, lng);
    //console.log(lat+"--"+lng);
    return new Promise(function(resolve, reject) {
    geocoder.geocode({'latLng': latlng}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[1]) {
            //resolve(results[0].address_components[3].long_name)
    for (var i=0; i<results[0].address_components.length; i++) {
            for (var b=0;b<results[0].address_components[i].types.length;b++) {
				//console.log(results[0].address_components[i].long_name)
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (results[0].address_components[i].types[b] == "administrative_area_level_2") {
                    //this is the object you are looking for
                    city= results[0].address_components[i];
                    resolve(city.long_name)
                    break;
                }
            }
        }
        //city data
		//console.log(city)
        // alert(city.short_name + " " + city.long_name)


        } else {
            reject(new Error('Couldnt\'t find the location ' + address));
        }
      } else {
        reject(new Error('Geocoder failed due to: ' + status));
      }
    });
    })
  };